<template>
  <div class="box">
    <div class="image" @click="$router.push('/')">
      <img src="../../assets/image/logo.png" alt="" />
    </div>
    <div class="content">
      <img
        src="../../assets/image/left_img.png"
        alt=""
        style="margin-left: 70px; margin-top: -10px"
      />
      <div class="login fr" v-if="isPhone">
        <p>搜老师优质教育资源</p>
        <div class="tab">
          <span :class="{ tabone: isshow == 1 }" @click="whichone(1)"
            >用户登录</span
          >
          <span style="margin: 0 10px">|</span>
          <span :class="{ tabone: isshow == 2 }" @click="whichone(2)"
            >学习卡登录</span
          >
        </div>
        <!-- 用户登录 -->
        <div v-if="isshow == 1">
          <div>
            <el-input
              clearable
              placeholder="手机号"
              prefix-icon="el-icon-mobile-phone"
              v-model="input1"
              @input="phoneLogin"
              @blur="phoneLogin"
            >
            </el-input>
            <div class="tip" v-if="isNumber">{{ tips1 }}</div>
            <div style="height: 12px" v-else></div>
          </div>

          <div>
            <el-input
              clearable
              placeholder="密码"
              v-model="input2"
              @blur="phonepwdLogin"
              @input="phonepwdLogin"
              type="password"
              prefix-icon="el-icon-lock"
            >
            </el-input>
            <div class="tip" v-if="isPassword">{{ tips2 }}</div>
            <div style="height: 12px" v-else></div>
          </div>
          <el-button type="primary" round class="btnlogin" @click="handleLogin1"
            >登录</el-button
          >
          <div class="clearfix" style="margin: 10px 0; padding: 0 10px">
            <el-button
              @click="$router.push('/register')"
              type="text"
              class="btn"
              >立即注册</el-button
            >
            <el-button
              type="text"
              class="fr btn"
              @click="$router.push('/forgetPassword')"
              >忘记密码？</el-button
            >
          </div>
        </div>
        <!-- 学习卡登录 -->
        <div v-else>
          <div>
            <el-input
              clearable
              placeholder="账号"
              prefix-icon="el-icon-user"
              v-model="input3"
              @input="cardLogin"
              @blur="cardLogin"
            >
            </el-input>
            <div class="tip" v-if="isCard">{{ tips3 }}</div>
            <div style="height: 12px" v-else></div>
          </div>

          <div>
            <el-input
              clearable
              placeholder="密码"
              v-model="input4"
              @blur="cardpwdLogin"
              @input="cardpwdLogin"
              type="password"
              prefix-icon="el-icon-lock"
            >
            </el-input>
            <div class="tip" v-if="isCardPwd">{{ tips4 }}</div>
            <div style="height: 12px" v-else></div>
          </div>
          <el-button type="primary" round class="btnlogin" @click="handleLogin2"
            >登录</el-button
          >
          <div class="clearfix" style="margin: 10px 0; padding: 0 10px">
            <el-button
              @click="$router.push('/register')"
              type="text"
              class="btn"
              >立即注册</el-button
            >
            <el-button
              type="text"
              class="fr btn"
              @click="$router.push('/forgetPassword')"
              >忘记密码？</el-button
            >
          </div>
        </div>
      </div>
      <div class="login2 fr" v-else>
        <p>搜老师优质教育资源</p>
        <div style="color: #477bee; text-align: center; margin: 40px 0">
          ———— 绑 定 手 机 号 ————
        </div>
        <div>
          <div style="margin: 20px 0">
            <el-input
              clearable
              placeholder="手机号"
              v-model="input5"
              prefix-icon="el-icon-mobile-phone"
              size="small"
              @input="hh2"
              @blur="hh2"
            >
            </el-input>
            <div class="tip" v-if="istwo">{{ tips5 }}</div>
            <div style="height: 12px" v-else></div>
          </div>
          <div style="margin: 20px 0">
            <el-input
              clearable
              size="small"
              placeholder="验证码"
              v-model="input6"
              prefix-icon="el-icon-lock"
              class="numcode"
              @input="hh3"
              @blur="hh3"
            >
            </el-input>
            <el-button
              style="width: 101px"
              size="small"
              :disabled="disabled"
              type="primary"
              @click="getCode"
              >{{ codenum }}</el-button
            >
            <div class="tip" v-if="isthree">{{ tips6 }}</div>
            <div style="height: 12px" v-else></div>
          </div>
          <el-button type="primary" round class="btnlogin" @click="handleLogin"
            >登录</el-button
          >
          <div class="gophone" @click="goPhone()">用手机号登录 &gt;&gt;</div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
      <p>版权及最终解释权归山东大课堂信息技术有限公司所有</p>
      <p>
        Copy right@2018 山东大课堂信息技术有限公司 all Rights Reserved 版权所有
      </p>
    </div> -->
  </div>
</template>

<script>
import URL from "../../api/index";
export default {
  data() {
    return {
      isPhone: true,
      isshow: 1,
      //手机号
      input1: "",
      input2: "",
      tips1: "请输入手机号",
      tips2: "请输入密码",
      isNumber: false,
      isPassword: false,
      // 账号
      input3: "",
      input4: "",
      tips3: "请输入账号",
      tips4: "请输入密码",
      isCard: false,
      isCardPwd: false,
      // 绑定手机号
      input5: "",
      input6: "",
      tips5: "请输入手机号",
      tips6: "请输入验证码",
      disabled: false,
      codenum: "获取验证码",
      countdown: 60,
      isthree: false,
      istwo: false,
    };
  },
  components: {},
  mounted() {},
  methods: {
    whichone(res) {
      this.isshow = res;
    },
    phoneLogin() {
      if (!this.input1) {
        this.isNumber = true;
      } else {
        this.isNumber = false;
        var tests =
          /^(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))+\d{8})$/;
        if (tests.test(this.input1)) {
          this.isNumber = false;
        } else {
          this.isNumber = true;
          this.tips1 = "请输入正确格式的手机号";
        }
      }
    },
    phonepwdLogin() {
      if (!this.input2) {
        this.isPassword = true;
      } else {
        this.isPassword = false;
        var re = /^[\w!-@#$%^&*]{6,20}$/;
        if (re.test(this.input2)) {
          this.isPassword = false;
        } else {
          this.isPassword = true;
          this.tips2 = "请输入6到20位字母或数字的密码";
        }
      }
    },
    // 手机号登录
    handleLogin1() {
      var data = {
        login_name: this.input1,
        password: this.input2,
      };
      if (!this.input2) {
        this.isPassword = true;
      }
      if (!this.input1) {
        this.isNumber = true;
      }
      if (!this.isNumber && !this.isPassword) {
        this.$post(URL.getlogin, data).then((res) => {
          if (res.state == 0) {
            localStorage.setItem("loginInfo", JSON.stringify(res.result));
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push("/");
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
    // 卡号 账号
    cardLogin() {
      if (!this.input3) {
        this.isCard = true;
      } else {
        this.isCard = false;
      }
    },
    // 卡号 密码
    cardpwdLogin() {
      if (!this.input4) {
        this.isCardPwd = true;
      } else {
        this.isCardPwd = false;
      }
    },
    handleLogin2() {
      var data = {
        cardname: this.input3,
        cardpwd: this.input4,
      };
      if (!this.input3) {
        this.isCard = true;
      }
      if (!this.input4) {
        this.isCardPwd = true;
      }
      if (!this.isCard && !this.isCardPwd) {
        this.$post(URL.getcardlogin, data).then((res) => {
          if (res.state == 0) {
            localStorage.setItem("loginInfo", JSON.stringify(res.result[0]));
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push("/");
          } else if (res.state == 101) {
            // 没有绑定手机号
            this.$message("首次登录，请绑定手机号");
            this.isPhone = false;
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
    // 绑定手机号
    getCode() {
      var data_code = {
        mobile: this.input5,
      };
      if (!this.input5) {
        this.istwo = true;
      }
      if (!this.istwo) {
        this.$post(URL.getvcode, data_code).then((res) => {
          if (res.state == 0) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.timer();
          } else {
            this.$message(res.msg);
          }
        });
      }
      this.countdown = 60;
    },
    timer() {
      // console.log(this.countdown);
      if (this.countdown > 0) {
        this.disabled = true;
        this.countdown--;
        this.codenum = "重新发送(" + this.countdown + ")";
        setTimeout(this.timer, 1000);
      } else {
        this.countdown = 0;
        this.codenum = "获取验证码";
        this.disabled = false;
      }
    },
    hh2() {
      if (!this.input5) {
        this.istwo = true;
      } else {
        this.istwo = false;
        var re =
          /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        if (re.test(this.input5)) {
          this.istwo = false;
        } else {
          this.istwo = true;
          this.tips5 = "请输入正确格式的手机号";
        }
      }
    },
    hh3() {
      if (!this.input6) {
        this.isthree = true;
      } else {
        this.isthree = false;
      }
    },
    handleLogin() {
      if (!this.input5) {
        this.istwo = true;
      }
      if (!this.input6) {
        this.isthree = true;
      }
      var data = {
        cardname: this.input3,
        cardpwd: this.input4,
        mobile: this.input5,
        code: this.input6,
      };
      if (!this.istwo && !this.isthree) {
        this.$post(URL.getcardlogin, data).then((res) => {
          if (res.state == 0) {
            localStorage.setItem("loginInfo", JSON.stringify(res.result));
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$router.push("/");
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
    goPhone(){
      this.$router.go(0)
    }
  },
};
</script>

<style lang='less' scoped>
.box {
  height: 100%;
  padding: 0;
  background: url(../../assets/image/img.png) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  .tip {
    margin-left: 32px;
    height: 12px;
    font-size: 12px;
    color: red;
  }
  .image {
    cursor: pointer;
    width: 1100px;
    padding-top: 50px;
    margin: 0 auto;
    img {
      width: 100px;
    }
  }
  .content {
    position: relative;
    margin: 76px auto 0;
    border-radius: 20px;
    width: 1100px;
    height: 530px;
    background-color: rgb(247, 247, 247);
    .login {
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      padding: 60px 70px 75px;
      p {
        font-size: 30px;
        font-weight: normal;
        color: #477bee;
        text-align: center;
        letter-spacing: 4px;
      }
    }
    .login2 {
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      padding: 60px 70px 53px;
      p {
        font-size: 30px;
        font-weight: normal;
        color: #477bee;
        text-align: center;
        letter-spacing: 4px;
      }
      .gophone {
        text-align: right;
        font-size: 12px;
        padding-top: 20px;
        color: #2a78fd;
        cursor: pointer;
      }
    }
  }

  .el-checkbox /deep/ .el-checkbox__label {
    font-size: 12px !important;
  }
  .btn {
    font-size: 12px !important;
  }
  .el-input {
    width: 320px;
    margin-top: 20px;
    margin-bottom: 6px;
  }
  .help {
    font-size: 12px;
    text-align: center;
    margin-top: 30px;
    span {
      padding: 0 16px;
    }
  }
  .bottom {
    position: absolute;
    text-align: center;
    bottom: 40px;
    left: 40%;
    color: #fff;
    font-size: 12px;
    p {
      margin-bottom: 20px;
    }
  }
}
.tab {
  padding: 30px 0;
  text-align: center;
  cursor: pointer;
  color: rgb(112, 112, 112);
  .tabone {
    color: #286efd;
  }
}
.btnlogin {
  width: 100%;
  margin-top: 20px;
  background-image: linear-gradient(-90deg, #286efd 0%, #3abcff 100%);
}
.el-input/deep/.el-input__icon {
  color: #105bf0;
}
.numcode {
  width: 200px !important;
  margin-right: 20px;
}
</style>

